import { FC } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { LaboratoryResultGroup, PatientLaboratoryResult } from '../../../interfaces/health-data';
import healthDataService from '../../../services/health-data';
import '../../common/tab-section.css';
import LaboratoryResultsForm, { OnSaveChangesArgs } from './Form/LaboratoryResultsForm';

const EditLaboratoryResult: FC = () => {
  const redirect = useRedirect();
  const notify = useNotify();

  const { location } = useHistory<{
    groupId: string;
    date: string;
    externalDataSource: LaboratoryResultGroup['externalDataSource'];
    labResults: PatientLaboratoryResult[];
    patient: LaboratoryResultGroup['patient'];
  }>();

  if (!location.state) {
    redirect('/laboratory-results');

    return null;
  }

  const { groupId, date, externalDataSource, patient, labResults } = location.state;

  const handleSaveChanges = async (args: OnSaveChangesArgs): Promise<void> => {
    const { externalDataSource, resultsOn, labResults } = args;

    const newLabs = labResults.filter((lr) => !lr.id);
    const existingLabResults = labResults.filter((lr) => !!lr.id);

    if (
      await healthDataService.editPatientLaboratoryResult(groupId, {
        ...(externalDataSource && { externalDataSource }),
        ...(date !== resultsOn && { resultsOn: new Date(resultsOn).toISOString() }),
        ...(newLabs.length > 0 && { newLabs }),
        labResults: existingLabResults,
      })
    ) {
      return redirect('/laboratory-results');
    }

    notify('We were unable to update laboratory results for this patient', 'error');
  };

  return (
    <LaboratoryResultsForm
      items={[{ text: groupId }]}
      isEditing
      externalDataSource={externalDataSource}
      patient={patient}
      resultsOn={date}
      labResults={labResults.map((r) => ({
        id: r.id,
        testName: r.interpretation!,
        code: r.code!,
        value: r.value!,
        unit: r.unit!,
        codeType: r.codeType,
        comments: r.comments,
      }))}
      onSaveChanges={handleSaveChanges}
    />
  );
};

export default EditLaboratoryResult;
