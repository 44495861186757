import {
  Box,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ClaimStage } from '../../../interfaces/billing';
import { BillingProcess } from '../../../interfaces/billing.rpm';
import { BillingService } from '../../../services';
import { formatDateString } from '../../../util/dateFormat';
import { ClaimSummary } from './ClaimSummary';

function getTabNameByProcessStatus(status: ClaimStage) {
  const tabNameByProcessStatus = {
    [ClaimStage.Created]: 'RPM Calculation',
    [ClaimStage.Exported]: 'RPM Export Results',
  };

  if (!status || !tabNameByProcessStatus[status]) {
    return 'Patient Selection';
  }

  return tabNameByProcessStatus[status];
}

export const ProcessesPanel = () => {
  const [selectedProcess, setSelectedProcess] = useState<BillingProcess | null>(null);
  const [isFetchingProcesses, setIsFetchingProcesses] = useState(false);
  const [processes, setProcesses] = useState<BillingProcess[]>([]);

  const fetchProcesses = useCallback(async () => {
    setIsFetchingProcesses(true);
    const billingServiceClient = new BillingService();
    const allProcesses = await billingServiceClient.getProcesses();
    const croppedList = allProcesses?.slice(0, 20); // TODO: remove this when the endpoint is paginated
    if (croppedList?.length) {
      setProcesses(croppedList);
    }
    setIsFetchingProcesses(false);
    return croppedList;
  }, []);

  const handleAfterAction = useCallback(async (currentProcessId: number | null) => {
    const newProcesses = await fetchProcesses();
    const newSelectedProcess = newProcesses?.find(({ id }) => id === currentProcessId);
    if (newSelectedProcess) {
      setSelectedProcess(newSelectedProcess);
    } else {
      setSelectedProcess(null);
    }
  }, []);

  useEffect(() => {
    fetchProcesses();
  }, []);

  return (
    <Stack gap={2} display='flex' width='100%'>
      <Box>
        <Typography variant='h6'>RPM Process History</Typography>
      </Box>
      <Paper sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box flex={1}>
          <List component='nav'>
            <ListItemButton
              selected={selectedProcess === null}
              onClick={() => setSelectedProcess(null)}>
              <ListItemText primary='+ New process' />
            </ListItemButton>
            {isFetchingProcesses ? (
              <Stack alignItems='center' gap={2} margin={5}>
                <CircularProgress />
                <Typography variant='body2'>Fetching processes</Typography>
              </Stack>
            ) : (
              <>
                {processes.map((process) => (
                  <ListItemButton
                    selected={selectedProcess?.id === process.id}
                    onClick={() => setSelectedProcess(process)}>
                    <ListItemText
                      primary={`#${process.id} Process left on ${getTabNameByProcessStatus(
                        process.status,
                      )}`}
                      secondary={`Last updated by ${
                        process.lastModifiedByUserFullName
                      } at ${formatDateString(process.lastModifiedAt)}`}
                    />
                  </ListItemButton>
                ))}
              </>
            )}
          </List>
        </Box>
        <Divider orientation='vertical' flexItem />
        <ClaimSummary process={selectedProcess} onAfterAction={handleAfterAction} />
      </Paper>
    </Stack>
  );
};
