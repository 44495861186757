import { Box, Chip, Link } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { BillingPagination, NoteExport } from '../../../interfaces/billing.rpm';
import { BillingService } from '../../../services';
import { formatDateStringToYYYYMMDD } from '../../../util/dateFormat';
import { getCapitalizedWord } from '../../../util/stringFormat';
import { CompactTable } from '../../common/CompactTable/CompactTable';
import { Column } from '../../common/CustomTable';
import { getExportedNoteStatusChipColor } from '../common/ChipColors';
import { RpmLoading } from '../common/RpmLoading';
import ProgressColumn from './ProgressColumn';

interface NoteExportsTableProps {
  isLoading?: boolean;
  data: NoteExport[];
  pagination: BillingPagination;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (rowsPerPage: number) => void;
}

const COLUMNS: Column[] = [
  {
    key: 'id',
    label: 'ID',
    align: 'left',
  },
  {
    key: 'status',
    label: 'Status',
    align: 'left',
  },
  {
    key: 'progress',
    label: 'Progress',
    align: 'center',
  },
  {
    key: 'createdAt',
    label: 'Created',
    align: 'left',
  },
  {
    key: 'updatedAt',
    label: 'Last Update',
    align: 'left',
  },
  {
    key: 'issues',
    label: 'Issues',
  },
  {
    key: 'actions',
    label: 'Actions',
    align: 'center',
  },
];

export const NoteExportsTable: React.FC<NoteExportsTableProps> = ({
  isLoading = false,
  data,
  pagination,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const handlePageChange = useCallback(
    (newPage: number) => {
      onPageChange(newPage);
    },
    [onPageChange],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      onRowsPerPageChange(newRowsPerPage);
    },
    [onRowsPerPageChange],
  );

  const handleDownloadClick = async (exportId: number) => {
    const url = await new BillingService().getNoteExportDownloadUrl(exportId);
    if (url) {
      window.open(url, '_blank');
    } else {
      console.error(`Failed to retrieve the pre-signed URL for exportId: ${exportId}`);
    }
  };

  const tableRows = useMemo(
    () =>
      data.map((exportItem) => ({
        id: exportItem.id,
        status: (
          <Chip
            label={getCapitalizedWord(exportItem.status)}
            size='small'
            sx={getExportedNoteStatusChipColor(exportItem.status)}
          />
        ),
        progress: (
          <ProgressColumn processed={exportItem.processedNotes} total={exportItem.totalNotes} />
        ),
        createdAt: formatDateStringToYYYYMMDD(exportItem.createdAt),
        updatedAt: formatDateStringToYYYYMMDD(exportItem.updatedAt),
        issues: exportItem.error,
        actions: exportItem.fileUrl ? (
          <Link
            component='button'
            onClick={() => handleDownloadClick(exportItem.id)}
            sx={{
              cursor: 'pointer',
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' },
            }}>
            Download
          </Link>
        ) : (
          ''
        ),
      })),
    [data],
  );

  return (
    <Box position='relative'>
      {isLoading && <RpmLoading />}
      <CompactTable
        columns={COLUMNS}
        rows={tableRows}
        defaultText='No exports found'
        pagination={{
          rowsPerPageOptions: [10, 20, 30, 50],
          rowPerPage: pagination.limit,
          page: pagination.currentPage,
          totalCount: pagination.totalCount,
          onPageChange: handlePageChange,
          onRowsPerPageChange: handleChangeRowsPerPage,
        }}
      />
    </Box>
  );
};
