import { AuditLog } from './audit-log';

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  perPage: number;
}

export enum RpmBillingStatus {
  BillableStrong = 'billable-strong',
  BillableRelevant = 'billable-relevant',
  NotBillable = 'not-billable',
  NotBillableForObesity = 'not-billable-for-obesity',
}

export enum ConditionPriority {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  BEHAVIORAL = 'behavioral',
}

export interface BillingCriteria {
  priority: ConditionPriority;
  status: RpmBillingStatus;
  excludable: boolean;
}

export enum ValidityTimeInterval {
  Days = 'days',
  Months = 'months',
  Years = 'years',
}

export enum ConditionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum LaboratoryTestStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ProcedureStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum MedicationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum LaboratoryResultRecordSource {
  ManualInput = 'manual-input',
  LlmParsing = 'llm-parsing',
}

export enum LaboratoryResultExternalDataSource {
  Labcorp = 'labcorp',
  LabQuest = 'lab-quest',
  DrChronoFax = 'drchrono-fax',
  StanfordPortal = 'stanford-portal',
  SutterPortal = 'sutter-portal',
  Metriport = 'metriport',
}

export enum LaboratoryResultSystemSource {
  OpsDashboard = 'ops-dashboard',
}

export interface Condition {
  id: number;
  name: string;
  icd10Code: string;
  description: string | null;
  status: ConditionStatus;
  includedInPvq: boolean;
  billingCriteria: BillingCriteria;
  createdAt: Date;
  updatedAt: Date;
}

export interface LaboratoryTest {
  id: number;
  name: string;
  status: LaboratoryTestStatus;
  units: string[];
  createdAt: Date;
  updatedAt: Date;
  loincCode: string;
}

export interface Procedure {
  name: string;
  id: number;
  status: ProcedureStatus;
  createdAt: Date;
  updatedAt: Date;
  cptCode: string;
}

export interface Medication {
  id: number;
  status: MedicationStatus;
  createdAt: Date;
  updatedAt: Date;
  conceptName: string;
  ndcCode: string;
  rxCuiCode: string;
}

export interface FindAllConditionsParams {
  status?: ConditionStatus;
  page?: number;
  pageSize?: number;
}

export type PartialSome<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export interface ConditionWithRelationsForm {
  condition: PartialSome<Condition, 'createdAt' | 'updatedAt' | 'id' | 'billingCriteria'> & {
    billingCriteria: {
      status: RpmBillingStatus;
      priority: ConditionPriority;
      excludable: boolean;
    };
  };
  laboratoryTests: (PartialSome<
    ConditionLaboratoryTest,
    'conditionId' | 'laboratoryTestId' | 'createdAt' | 'updatedAt' | 'id'
  > & { laboratoryTest: LaboratoryTest })[];
  procedures: (PartialSome<
    ConditionProcedure,
    'conditionId' | 'procedureId' | 'createdAt' | 'updatedAt' | 'id'
  > & { procedure: Procedure })[];
  medications: (PartialSome<
    ConditionMedication,
    'conditionId' | 'medicationId' | 'createdAt' | 'updatedAt' | 'id'
  > & { medication: Medication })[];
}

export interface ConditionWithRelations {
  condition: Condition;
  laboratoryTests: (ConditionLaboratoryTest & { laboratoryTest: LaboratoryTest })[];
  procedures: (ConditionProcedure & { procedure: Procedure })[];
  medications: (ConditionMedication & { medication: Medication })[];
}

export type FindAllConditionsResponse = {
  conditions: ConditionWithRelations[];
  pagination: Pagination;
};

export type ConditionLaboratoryTest = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  conditionId: number;
  laboratoryTestId: number;
  validityPeriodFrom: string;
  validityPeriodUntil: string;
};

export type ConditionProcedure = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  conditionId: number;
  validityPeriodFrom: string;
  validityPeriodUntil: string;
  procedureId: number;
};

export type ConditionMedication = {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  conditionId: number;
  medicationId: number;
};

export interface CreateConditionPayload {
  name: string;
  icd10Code: string;
  includedInPvq: boolean;
  description?: string;
  billingCriteria: BillingCriteria;
  status?: ConditionStatus;
  laboratoryTests: {
    id: number;
    validityPeriodFrom: string;
    validityPeriodUntil: string;
  }[];
  procedures: {
    id: number;
    validityPeriodFrom: string;
    validityPeriodUntil: string;
  }[];
  medications: {
    id: number;
  }[];
}

export interface CreateConditionResponse {
  condition: Condition;
  laboratoryTests: ConditionLaboratoryTest[];
  procedures: ConditionProcedure[];
  medications: ConditionMedication[];
}

export interface UpdateConditionPayload {
  name?: string;
  icd10Code?: string;
  status?: ConditionStatus;
  laboratoryTests?: {
    id: number;
    validityPeriodFrom: string;
    validityPeriodUntil: string;
  }[];
  procedures?: {
    id: number;
    validityPeriodFrom: string;
    validityPeriodUntil: string;
  }[];
  medications?: {
    id: number;
  }[];
  includedInPvq?: boolean;
  description?: string;
  billingCriteria?: BillingCriteria;
}

export enum MedicalSetupEntityType {
  Conditions = 'conditions',
  LaboratoryTests = 'laboratory-tests',
  Medications = 'medications',
  Procedures = 'procedures',
}

export interface CreateProcedurePayload {
  status: ProcedureStatus;
  cptCode: string;
  name: string;
}

export type UpdateProcedurePayload = Partial<CreateProcedurePayload>;

export type EntityMapping = {
  [MedicalSetupEntityType.LaboratoryTests]: LaboratoryTest;
  [MedicalSetupEntityType.Medications]: Medication;
  [MedicalSetupEntityType.Procedures]: Procedure;
};
export interface LaboratoryTestsResponse {
  laboratoryTests: LaboratoryTest[];
  pagination: Pagination;
}

export interface MedicationsResponse {
  medications: Medication[];
  pagination: Pagination;
}

export interface ProceduresResponse {
  procedures: Procedure[];
  pagination: Pagination;
}

export type MedicalSetupEntitiesResponse<K extends keyof EntityMapping> =
  K extends MedicalSetupEntityType.LaboratoryTests
    ? LaboratoryTestsResponse
    : K extends MedicalSetupEntityType.Medications
    ? MedicationsResponse
    : K extends MedicalSetupEntityType.Procedures
    ? ProceduresResponse
    : never;

export interface CreateLaboratoryTestPayload {
  name: string;
  status?: LaboratoryTestStatus;
  loincCode: string;
  units: string[];
}

export type UpdateLaboratoryTestPayload = Partial<CreateLaboratoryTestPayload>;

export interface CreateMedicationPayload {
  conceptName: string;
  status?: MedicationStatus;
  ndcCode: string;
  rxCuiCode: string;
}

export type UpdateMedicationPayload = Partial<CreateMedicationPayload>;

export interface FindAllPatientLaboratoryResultsParams {
  searchString?: string;
  page?: number;
  pageSize?: number;
}

export interface PatientLaboratoryResult {
  id: string;
  patientLaboratoryResultGroupId: string;
  comments?: string;
  value?: string;
  unit?: string;
  interpretation?: string;
  codeType?: string;
  code?: string;
}

export interface LaboratoryResultGroup {
  groupId: string;
  date: string;
  externalDataSource: LaboratoryResultExternalDataSource | null;
  patient: {
    internalId: number;
    internalUuid: string;
    fullName: string;
    clinic: {
      id: number | null;
      name: string | null;
    };
  };
  labResults: PatientLaboratoryResult[];
}

export interface FindAllLaboratoryResultsResponse {
  labResultGroups: LaboratoryResultGroup[];
  pagination: Pagination;
}

export interface NewIncomingPatientLaboratoryResult {
  testName: string;
  code: string;
  value: string;
  unit: string;
  codeType?: string;
  comments?: string;
}

export type IncomingPatientLaboratoryResult = { id?: string } & NewIncomingPatientLaboratoryResult;
export type EnrichedIncomingPatientLaboratoryResult = {
  referenceName?: string;
} & IncomingPatientLaboratoryResult;

export interface UpdatePatientLaboratoryResultPayload {
  externalDataSource?: LaboratoryResultExternalDataSource;
  resultsOn?: string;
  newLabs?: NewIncomingPatientLaboratoryResult[];
  labResults: IncomingPatientLaboratoryResult[];
}

export interface LaboratoryResultPatient {
  internalUuid: string;
  clinicName: string;
  fullName: string;
}

export interface CreatePatientLaboratoryResultPayload {
  recordSource: LaboratoryResultRecordSource;
  externalDataSource: LaboratoryResultExternalDataSource;
  patient: LaboratoryResultPatient;
  resultsOn: string;
  labResults: NewIncomingPatientLaboratoryResult[];
}
export interface Patient {
  id: string;
  internalUuid: string;
  internalId: string | null;
  internalSource: string | null;
  clinicName: string | null;
  name: string;
  dob: string | null;
  gender: string | null;
  authorId: string | null;
}

export enum PatientConditionStatus {
  Active = 'Active',
  Resolved = 'Resolved',
  Recurrence = 'Recurrence',
  Empty = '',
}

export interface PatientCondition {
  id: string;
  patientId: string;
  status: PatientConditionStatus;
  code: string | null;
  conditionId: number;
  condition: string;
  firstSeen: string | null;
  lastSeen: string | null;
  deleteAt: string | null;
}

export type PatientConditionFromReport = PatientCondition & {
  associatedCondition: Condition | null;
};

export type ConditionsWithPatient = {
  conditions: PatientConditionFromReport[];
  patient: Patient;
};

export type FindConditionsRelatedToPatientResponse = {
  conditions: PatientConditionFromReport[];
  pagination: Pagination;
  patient: Patient;
};

export interface retrieveConditionsByPatientResponse {
  conditionsByPatient: { conditions: PatientConditionFromReport[]; patient: Patient }[];
  pagination: Pagination;
}

export type FindPatientHistoricalConditionsResponse = {
  conditions: PatientConditionFromReport[];
  patient: Patient;
  pagination: Pagination;
};

export type FindPatientConditionsResponse = {
  conditions: PatientConditionFromReport[];
  patient: Patient;
  pagination: Pagination;
};

export interface UpdatePatientConditionsResponse {
  patient: {
    internalId: string | null;
    internalUuid: string;
  };
  conditions: PatientConditionFromReport[];
}

export type UpdatePatientConditionsPayload = {
  patientId: string;
  conditions: {
    update: { id: string; status: PatientConditionStatus }[];
    create: {
      patientId: string;
      code: string;
      condition: string;
      status: PatientConditionStatus;
    }[];
  };
};

export type FindAllAuditLogsByUser = {
  logs: AuditLog[];
  pagination: Pagination;
};

export interface ExtractedLaboratoryResultsOutput {
  testName: string;
  value: string;
  unit: string;
  referenceRange: string;
}
