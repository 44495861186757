import { ClaimStage } from './billing';

export interface RPMNoteDevice {
  name: string;
  type: string;
}

export interface RPMNoteCPTCode {
  code: string;
  description?: string;
}

export interface RPMNoteInteraction {
  date: string;
  type: string;
  description: string;
}

export interface RPMNoteMedication {
  name: string;
  dispense: number;
  sig: string;
  isPRN?: boolean;
  indication?: string | null;
}

export interface RPMNoteAllergy {
  allergen: string;
  reaction?: string;
}

export interface RPMNoteDiagnosis {
  code: string;
  description: string;
  type: string;
}

export interface RPMNotePlanItem {
  type: string;
  description: string;
  cptCode?: string;
  modifiers?: string;
  quantity?: number;
}

export interface RPMNoteSubSection {
  key: string;
  content: string | RPMNoteSectionContent;
  isSubSection?: boolean;
  isTable?: boolean;
}

export type RPMNoteSectionContent =
  | string
  | RPMNoteDevice[]
  | RPMNoteCPTCode[]
  | RPMNoteInteraction[]
  | RPMNoteMedication[]
  | RPMNoteAllergy[]
  | RPMNoteDiagnosis[]
  | RPMNotePlanItem[]
  | RPMNoteSubSection[];

export interface RPMNoteSection {
  order: number;
  title: string;
  content?: RPMNoteSectionContent;
  isSingleLine?: boolean;
  isSubSection?: boolean;
  isTable?: boolean;
  key: string;
}

export interface RPMNoteFooter {
  centerText: string;
  poweredBy: string;
  datetime: string;
  providerFullName: string;
}

export interface RPMNoteHeader {
  patient: string;
  dateOfBirth: string;
  sex: string;
  chartNumber: string;
  provider: string;
  visitDate: string;
}

export interface RPMNoteContent {
  header: RPMNoteHeader;
  sections: RPMNoteSection[];
  footer: RPMNoteFooter;
}

export enum RPMNoteSectionKey {
  CHIEF_COMPLAINT = 'chiefComplaint',
  REMOTE_PATIENT_MONITORING = 'remotePatientMonitoring',
  PHYSICIAN_ORDER_DEVICE_INFO = 'physicianOrderAndDeviceInformation',
  DAILY_RECORDINGS_ALERTS = 'dailyRecordingsAndAlerts',
  REMOTE_MONITORING_INTERACTION = 'remotePhysiologicalMonitoringAndInteraction',
  SUMMARY_MONITORING_MANAGEMENT = 'summaryOfMonitoringAndManagement',
  INTERACTION_LOG = 'interactionLog',
  MEDICATIONS_ALLERGIES = 'medicationsAndAllergies',
  ASSESSMENT = 'assessment',
  PLAN = 'plan',
}

export enum RPMNoteSubSectionKey {
  FDA_DEVICES = 'fdaDevices',
  CPT_CODES = 'cptCodes',
  PHYSICIAN_ORDER = 'physicianOrder',
  DEVICE_USAGE = 'deviceUsage',
  MONITORING_PERIOD = 'monitoringPeriod',
  DATA_TRANSMISSION = 'dataTransmission',
  INTERACTION_DETAILS = 'interactionDetails',
  PATIENT_MANAGEMENT = 'patientManagement',
  PATIENT_CONSENT = 'patientConsent',
  MONITORING_DAYS = 'monitoringDays',
  PHYSIOLOGICAL_DATA = 'physiologicalData',
  FEEDBACK = 'feedback',
  CURRENT_MEDICATIONS = 'currentMedications',
  ALLERGIES = 'allergies',
}

export interface BillingPatientsResponse {
  data: BillingPatient[];
  pagination: BillingPagination;
}

export interface BillingPatient {
  billingCycleRange: BillingCycleRange;
  clinicId: number;
  clinicName: string;
  coverageType: string;
  cptCodesAssociated: string[];
  createdAt: Date;
  firstName: string;
  hintId: string;
  icd10Codes: string[];
  id: number;
  initialMdVisitDate: null;
  initialMedicalAppointmentDate: string;
  insuranceCompany: string;
  insuranceCompanyId: string;
  insuranceCompanyPayerId: string;
  lastDateBillingCycle: null;
  lastExportDate: null;
  lastName: string;
  lastSyncAt: Date;
  memberId: number;
  membershipComputeStatus: string;
  membershipOriginalStatus: string;
  membershipStartDate: string;
  membershipId: string;
  membershipType: string;
  memberUuid: string;
  profileStatus: string;
  profileStatusId: number;
  rpmExcluded: boolean;
  rpmExclusionCriteria: string[];
  rpmExportStatus: string;
  rpmWarnings: string[];
  updatedAt: Date;
  uuid: string;
  clinicPayerConfig: BillingClinicPayerConfig;
}

export interface BillingClinicPayerConfig {
  payerId: string;
  clinicId: number;
  rpmEnabled: boolean;
  cptCodesConfig: { [key: string]: CptCodesConfig };
}

export interface CptCodesConfig {
  enabled: boolean;
  max_claims_per_cycle: number;
  pos: number;
}

export interface BillingCycleRange {
  from: string;
  to: string;
}

export interface BillingPagination {
  totalCount: number;
  totalPages: number;
  currentPage: number;
  limit: number;
}

export interface BillingCptCodes {
  id: number;
  claimId: number;
  cptCode: string;
  units: number;
}

export interface BillingMember {
  insuranceCompany: string;
  insuranceCompanyId: string;
  firstName: string;
  lastName: string;
  clinicId: number;
  clinicName: string;
}

export interface BillingRPMNote {
  pdfUrl: string;
}

export interface BillingClaimPreview {
  id: number;
  dateOfService: string;
  startDate: string;
  status: string;
  cptCodes: BillingCptCodes[];
  errors: string[];
  memberName: string;
  clinicName: string;
  billingMember: BillingMember;
  placeOfService: string;
}

export interface BillingClaim {
  id: number;
  dateOfService: string;
  startDate: string;
  status: string;
  cptCodes: BillingCptCodes[];
  errors: string[];
  placeOfService: string;
  invoiceAmount: string;
  billingMember: BillingMember;
  invoiceId: string;
  rpmNotes: BillingRPMNote;
  processId: number;
}

export interface BillingClaimsPreviewResponse {
  data: BillingClaimPreview[];
  pagination: BillingPagination;
}

export interface BillingClaimsExportedResponse {
  data: BillingClaim[];
  pagination: BillingPagination;
}

export interface BillingNoteResponse {
  success: boolean;
  note: RPMNoteContent;
}

export interface ClaimLog {
  id: number;
  status: string;
  stage: string;
  date: string;
  description: string;
}

export interface BillingClaimLogResponse {
  data: ClaimLog[];
  pagination: BillingPagination;
}

export interface BillingProcess {
  id: number;
  status: ClaimStage;
  startedByUserUuid: string;
  lastModifiedAt: string;
  lastModifiedByUserUuid: string;
  lastModifiedByUserFullName: string;
  createdAt: string;
  updatedAt: string;
}

export interface BillingUser {
  uuid: string;
  fullName: string;
}

export enum DownloadStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface NoteExport {
  id: number;
  status: DownloadStatus;
  format: string;
  fileUrl: string | null;
  error: string | null;
  processedNotes: number;
  totalNotes: number;
  createdAt: string;
  updatedAt: string;
  claims: NoteExportClaim[];
}

export interface NoteExportClaim {
  noteExportId: number;
  claimId: number;
}

export interface NoteExportsResponse {
  data: NoteExport[];
  pagination: BillingPagination;
}

export interface GetNoteExportsParams {
  status?: DownloadStatus;
  clinicIds?: number[];
  dateFrom?: string;
  dateTo?: string;
  page?: number;
  limit?: number;
}
