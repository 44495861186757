import { FC, useMemo } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { useHistory } from 'react-router-dom';
import {
  EnrichedIncomingPatientLaboratoryResult,
  ExtractedLaboratoryResultsOutput,
  LaboratoryResultRecordSource,
} from '../../../interfaces/health-data';
import healthDataService from '../../../services/health-data';
import LaboratoryResultsForm, { OnSaveChangesArgs } from './Form/LaboratoryResultsForm';
import { ALL_LABORATORY_TESTS, DEFAULT_CODE_TYPE } from './Form/LaboratoryTestSelector';

const LaboratoryResultParseDocumentInput: FC = () => {
  const redirect = useRedirect();
  const notify = useNotify();

  const { location } = useHistory<{ labs: ExtractedLaboratoryResultsOutput[] | null }>();

  const enrichedLabResults: EnrichedIncomingPatientLaboratoryResult[] | undefined = useMemo(() => {
    const laboratoryTests = Object.values(ALL_LABORATORY_TESTS);

    const { labs } = location.state || { labs: [] };

    return labs?.map((parsedLabResult) => {
      const matchingLabTest = laboratoryTests.find(
        ({ name }) => name.toLowerCase().trim() === parsedLabResult.testName.toLowerCase().trim(),
      );

      return {
        testName: matchingLabTest?.name || '',
        code: matchingLabTest?.loincCode || '',
        value: parsedLabResult.value,
        unit: parsedLabResult.unit,
        codeType: DEFAULT_CODE_TYPE,
        comments: parsedLabResult.referenceRange,
        referenceName: parsedLabResult.testName,
      };
    });
  }, [location.state]);

  if (!location.state) {
    redirect('/laboratory-results');

    return null;
  }

  const handleSaveChanges = async (args: OnSaveChangesArgs): Promise<void> => {
    const { externalDataSource, patient, resultsOn, labResults } = args;

    if (
      await healthDataService.createLaboratoryResults({
        recordSource: LaboratoryResultRecordSource.LlmParsing,
        externalDataSource,
        patient,
        resultsOn: new Date(resultsOn).toISOString(),
        labResults,
      })
    ) {
      return redirect('/laboratory-results');
    }

    notify('We were unable to create laboratory results for this patient', 'error');
  };

  return (
    <LaboratoryResultsForm
      items={[
        { text: 'add new lab', location: '/laboratory-results/add' },
        { text: 'labs parsing output' },
      ]}
      labResults={enrichedLabResults}
      onSaveChanges={handleSaveChanges}
    />
  );
};

export default LaboratoryResultParseDocumentInput;
