import { Box, LinearProgress, Typography } from '@mui/material';

const ProgressColumn = ({ processed, total }: { processed: number; total: number }) => {
  const percentage = Math.round((processed / total) * 100);

  return (
    <Box sx={{ width: '100%', minWidth: 150 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 0.5,
        }}>
        <Typography variant='body2'>
          {processed} de {total}
        </Typography>
        <Typography variant='body2'>{percentage}%</Typography>
      </Box>
      <LinearProgress
        variant='determinate'
        value={percentage}
        sx={{
          height: 8,
          borderRadius: 4,
          backgroundColor: 'rgb(229, 239, 255)',
          '& .MuiLinearProgress-bar': {
            backgroundColor: 'rgb(25, 118, 210)',
            borderRadius: 4,
          },
        }}
      />
    </Box>
  );
};

export default ProgressColumn;
