import { Chip } from '@mui/material';

export const parseBillingLabel = (text: string) => {
  try {
    if (typeof text !== 'string') return '';
    return text.toLowerCase().split('_').join('-');
  } catch {
    return '';
  }
};

export const formatInsurance = (company: string, id: string) => {
  if (!company && !id) return <Chip size='small' label='Missing Insurance' />;
  if (!company) return `(${id})`;
  if (!id) return company;
  return `(${id}) ${company}`;
};
