import { Box, Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useAuthProvider, useNotify } from 'react-admin';
import { Identity } from '../../../interfaces/auth';
import { RpmCalculationFilters } from '../../../interfaces/billing';
import { BillingClaimPreview, BillingPagination } from '../../../interfaces/billing.rpm';
import { IClinic } from '../../../interfaces/clinic';
import { SelectOption } from '../../../interfaces/common';
import { PayerItem } from '../../../interfaces/eligibility';
import { BillingService } from '../../../services';
import { CommonService } from '../../../services/common';
import coreService from '../../../services/core';
import eligibilityService from '../../../services/eligibility';
import { formatDateStringToYYYYMMDD } from '../../../util/dateFormat';
import { INVALID_STORAGE_ERROR } from '../common/constants';
import { RPMFilters } from '../common/RPMFilters';
import { ActionableTab } from '../common/types';
import {
  FilterConfiguration,
  Filters,
  INITIAL_PAGINATION,
  LoadingStates,
  RPMCptCodes,
} from '../types/rpms.types';
import { DiscardCalculationModal } from './DiscardCalculationModal';
import { ProcessClaimsModal } from './ProcessClaimsModal';
import { RPMCalculationTable } from './RPMCalculationTable';

type RPMCalculationProps = ActionableTab & {
  processId?: number;
};

const filterConfigurations = [
  {
    key: 'clinic',
    label: 'Clinic',
    options: [],
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'insuranceCompany',
    label: 'Insurance Company',
    options: [],
    section: 'Patient Selection',
    type: 'autocomplete',
    fetchOptions: async (searchTerm: string) => {
      try {
        const response = await eligibilityService.getPayers({
          filter: searchTerm || null,
        });
        const options =
          response?.data?.map((payer: PayerItem) => ({
            label: payer.name,
            value: payer.id,
          })) || [];
        return options;
      } catch (error) {
        console.error('Error fetching insurance companies:', error);
        return [];
      }
    },
  },
  {
    key: 'dateOfService',
    label: 'Date Of Service',
    type: 'date',
  },
  {
    key: 'cptCodes',
    label: 'CPT Codes',
    options: Object.values(RPMCptCodes).map((value) => ({ value, label: value })),
    section: 'Patient Selection',
    type: 'select',
  },
  {
    key: 'memberSearch',
    label: 'Member Search',
    options: [],
    section: 'Patient Selection',
    type: 'autocomplete',
    fetchOptions: async (searchTerm: string) => {
      try {
        if (!searchTerm) {
          return [];
        }

        const response = await coreService.fetchMembers({
          searchTerm,
        });
        const options =
          response?.data?.map((member) => ({
            label: member.fullName,
            value: member.uuid,
          })) || [];
        return options;
      } catch (error) {
        console.error('Error fetching members:', error);
        return [];
      }
    },
  },
] as FilterConfiguration<Filters>[];

const initialFilters: Filters = {
  clinic: [],
  insuranceCompany: [],
  dos: [],
  cptCodes: [],
  memberSearch: [],
};

export const RPMCalculation: React.FC<RPMCalculationProps> = ({ onAfterAction, processId }) => {
  const [claimsData, setClaimsData] = useState<BillingClaimPreview[]>([]);
  const [pagination, setPagination] = useState<BillingPagination>(INITIAL_PAGINATION);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [clinicOptions, setClinicOptions] = useState<SelectOption[]>([]);
  const [loadingStates, setLoadingStates] = useState<LoadingStates>({
    isApplying: false,
    isClearing: false,
    isProcessing: false,
    isDiscarding: false,
  });
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const [isProcessModalOpen, setIsProcessModalOpen] = useState(false);
  const [skipEffect, setSkipEffect] = useState(false);
  const [allRecordsSelected, setAllRecordsSelected] = useState(false);
  const [excludedRows, setExcludedRows] = useState<{ id: number; index: number }[]>([]);

  const authProvider = useAuthProvider();
  const notify = useNotify();

  const buildRpmParameters = (
    currentFilters: Filters,
    page: number,
    limit: number,
    options: {
      includeDate?: boolean;
      includeFilters?: boolean;
    } = {
      includeDate: true,
      includeFilters: true,
    },
  ): RpmCalculationFilters => {
    const parameters: RpmCalculationFilters = {
      page,
      limit,
      clinicIds: [],
      insuranceCompanyIds: [],
      cptCodes: [],
      memberUuids: [],
      dateOfService: undefined,
      processId,
    };

    if (options.includeFilters) {
      parameters.clinicIds = currentFilters.clinic.map((clinic) => parseInt(clinic, 10));
      parameters.insuranceCompanyIds = currentFilters.insuranceCompany || [];
      parameters.cptCodes = currentFilters.cptCodes;
      parameters.memberUuids = currentFilters.memberSearch || [];
    }

    if (options.includeDate && currentFilters.dateOfService) {
      parameters.dateOfService = currentFilters.dateOfService?.[0]
        ? formatDateStringToYYYYMMDD(currentFilters.dateOfService?.[0])
        : '';
    }

    return parameters;
  };

  const fetchClinics = async () => {
    try {
      const clinics = await CommonService.getClinics();
      setClinicOptions(
        clinics.map((clinic: IClinic) => ({ label: clinic.name, value: `${clinic.id}` })),
      );
    } catch (error) {
      console.error('Error fetching clinics:', error);
    }
  };

  const fetchClaims = async (parameters: RpmCalculationFilters) => {
    setLoadingStates((prev) => ({ ...prev, isApplying: true }));

    try {
      const response = await new BillingService().getClaimsCalculated(parameters);
      setClaimsData(response?.data || []);
      setPagination(response?.pagination || INITIAL_PAGINATION);
    } catch (error) {
      console.error('Error fetching claims:', error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, isApplying: false }));
    }
  };

  const handlePageChange = (page: number) => {
    const parameters = buildRpmParameters(filters, page, pagination.limit);
    fetchClaims(parameters);
  };

  const handleRowsPerPageChange = (newLimit: number) => {
    const parameters = buildRpmParameters(filters, pagination.currentPage, newLimit);
    fetchClaims(parameters);
  };

  const exportClaimsWithSelection = async (
    billingService: BillingService,
    allRecordsSelected: boolean,
    selectedRows: number[],
    filters: Filters,
    excludedRows: { id: number; index: number }[],
    claimsData: BillingClaimPreview[],
  ) => {
    const identity = (await authProvider.getIdentity!()) as Identity;

    if (!identity?.uuid || !identity?.fullName) {
      notify(INVALID_STORAGE_ERROR, 'error');
      return;
    }

    if (allRecordsSelected) {
      return billingService.exportClaimsWithFilters(
        {
          clinicIds: filters.clinic?.map((clinicId) => Number.parseInt(clinicId)),
          insuranceCompanyIds: filters.insuranceCompany,
          dateOfService: formatDateStringToYYYYMMDD(filters.dateOfService as unknown as Date),
          cptCodes: filters.cptCodes,
          memberUuids: filters.memberSearch,
        },
        { uuid: identity.uuid, fullName: identity.fullName },
        excludedRows.length ? excludedRows.map(({ id }) => id) : undefined,
      );
    }

    const claimsMap = new Map(claimsData.map((claim) => [claim.id, claim]));
    const claimIds = selectedRows
      .map((index) => claimsMap.get(index)?.id)
      .filter((id): id is number => id !== undefined);
    return billingService.exportClaims(claimIds, {
      uuid: identity.uuid,
      fullName: identity.fullName,
    });
  };

  const updatedFilterConfigurations = useMemo(
    () =>
      filterConfigurations.map((config) =>
        config.key === 'clinic' ? { ...config, options: clinicOptions } : config,
      ),
    [clinicOptions],
  );

  useEffect(() => {
    fetchClinics();
  }, []);

  useEffect(() => {
    if (!skipEffect) {
      const parameters = buildRpmParameters(filters, pagination.currentPage, pagination.limit);
      fetchClaims(parameters);
    }
  }, [filters, pagination.currentPage, pagination.limit, skipEffect, processId]);

  const handleApplyFilters = async (appliedFilters: typeof initialFilters) => {
    setLoadingStates((prev) => ({ ...prev, isApplying: true }));
    try {
      const parameters = buildRpmParameters(appliedFilters, 1, pagination.limit);
      await fetchClaims(parameters);
      setFilters(appliedFilters);
    } catch (error) {
      console.error('Error applying filters:', error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, isApplying: false }));
    }
  };

  const handleClearFilters = async () => {
    setLoadingStates((prev) => ({ ...prev, isClearing: true }));
    setSkipEffect(true);

    try {
      await getInitialClaimsRequest();
      setFilters(initialFilters);
      setSelectedRows([]);
      setPagination((prev) => ({ ...prev, currentPage: 1 }));
    } finally {
      setLoadingStates((prev) => ({ ...prev, isClearing: false }));
      setSkipEffect(false);
    }
  };

  const handleProcessFilters = () => {
    setIsProcessModalOpen(true);
  };

  const handleDiscardProcess = () => {
    setIsDiscardModalOpen(true);
  };

  const handleConfirmDiscard = async () => {
    const identity = (await authProvider.getIdentity!()) as Identity;

    if (!identity?.uuid || !identity?.fullName) {
      notify(INVALID_STORAGE_ERROR, 'error');
      return;
    }

    try {
      const response = await new BillingService().discardClaims(processId!, {
        fullName: identity.fullName,
        uuid: identity.uuid,
      });
      if (!response) {
        setLoadingStates((prev) => ({ ...prev, isDiscarding: true }));
        setIsDiscardModalOpen(false);
        setSelectedRows([]);
        notify(`can't discard the claims`, 'warning');

        return;
      }

      notify(`${response.message}`, 'success');

      setLoadingStates((prev) => ({ ...prev, isDiscarding: true }));
      setIsDiscardModalOpen(false);
      setSelectedRows([]);
      onAfterAction(null);
    } catch (error) {
      console.error('Error discarding calculation:', error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, isDiscarding: false }));
    }
  };

  const handleConfirmProcess = async () => {
    setLoadingStates((prev) => ({ ...prev, isProcessing: true }));
    try {
      const billingService = new BillingService();
      const response = await exportClaimsWithSelection(
        billingService,
        allRecordsSelected,
        selectedRows,
        filters,
        excludedRows,
        claimsData,
      );

      if (response) {
        onAfterAction(processId ?? null);
      }
    } catch (error) {
      console.error('Error processing claims:', error);
    } finally {
      setLoadingStates((prev) => ({ ...prev, isProcessing: false }));
      setIsProcessModalOpen(false);
    }
  };

  const getInitialClaimsRequest = async () => {
    const parameters = buildRpmParameters(initialFilters, 1, pagination.limit, {
      includeFilters: false,
    });
    await fetchClaims(parameters);
  };

  return (
    <Box>
      <RPMFilters<Filters>
        configurations={updatedFilterConfigurations}
        onApplyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
        onProcessFilters={handleProcessFilters}
        onProcessLabel={
          allRecordsSelected ? 'Export all selected claims' : `Export ${selectedRows.length} claims`
        }
        initialFilters={initialFilters}
        loadingStates={loadingStates}
        selectedCount={selectedRows.length}
        additionalButtons={
          <>
            <Button variant='contained' onClick={handleDiscardProcess} disabled={!processId}>
              Discard Process
            </Button>
          </>
        }
        allRecordsSelection={allRecordsSelected}
      />
      <RPMCalculationTable
        onRowSelectionChange={setSelectedRows}
        selectedRows={selectedRows}
        onRowExclusionChange={setExcludedRows}
        excludedRows={excludedRows}
        onAllRecordsSelected={setAllRecordsSelected}
        allRecordsSelected={allRecordsSelected}
        data={claimsData}
        pagination={pagination}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        isLoading={loadingStates.isApplying}
      />

      <DiscardCalculationModal
        open={isDiscardModalOpen}
        onClose={() => setIsDiscardModalOpen(false)}
        onConfirm={handleConfirmDiscard}
        isLoading={loadingStates.isDiscarding}
      />

      <ProcessClaimsModal
        open={isProcessModalOpen}
        onClose={() => setIsProcessModalOpen(false)}
        onConfirm={handleConfirmProcess}
        claimsCount={selectedRows.length}
        isLoading={loadingStates.isProcessing}
        allClaimsSelected={allRecordsSelected}
      />
    </Box>
  );
};
