import { formatDate } from '@fullcalendar/core';
import BugReportIcon from '@mui/icons-material/BugReport';
import { Box, Checkbox, Chip, Link, Tooltip } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { BillingClaim, BillingPagination } from '../../../interfaces/billing.rpm';
import { BillingService } from '../../../services';
import { StyledTheme } from '../../../styles/styleTheme';
import { getCapitalizedWord } from '../../../util/stringFormat';
import { getAperoUrl } from '../../../util/url';
import { CompactTable } from '../../common/CompactTable/CompactTable';
import { Column } from '../../common/CustomTable';
import { BillingLink } from '../common/BillingLink';
import { getStatusChipColor } from '../common/ChipColors';
import { ClaimLogsModal } from '../common/ClaimLogsModal';
import { CodeChipList } from '../common/CodeChipList';
import { RpmLoading } from '../common/RpmLoading';
import { formatInsurance } from '../common/Text';
import { RpmStatus } from '../types/rpms.types';

const COLUMN_GROUPS = [
  {
    id: 'claims',
    title: 'Calculated claims for the period',
    startColumn: 4,
    colspan: 6,
  },
];

interface RPMExportResultsTableProps {
  data: BillingClaim[];
  pagination: BillingPagination;
  isLoading: boolean;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RPMExportResultsTable: React.FC<RPMExportResultsTableProps> = ({
  data,
  pagination,
  isLoading,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const [isLogsModalOpen, setIsLogsModalOpen] = useState(false);
  const [selectedClaimId, setSelectedClaimId] = useState<number | null>(null);
  const [selectedClaimIds, setSelectedClaimIds] = useState<number[]>([]);

  const columns: Column[] = useMemo(() => {
    return [
      {
        key: 'select',
        label: (
          <Tooltip title='Select/deselect all valid patients across pages' placement='right'>
            <span>
              <Checkbox
                size='small'
                style={{ color: StyledTheme.tealDark }}
                onChange={(event) =>
                  event.target.checked ? handleSelectAll() : handleDeselectAll()
                }
                aria-label='Select Valid Rows'
              />
            </span>
          </Tooltip>
        ),
        align: 'center',
      },
      {
        key: 'id',
        label: 'Claim ID',
      },
      {
        key: 'name',
        label: 'Name',
      },
      {
        key: 'clinic',
        label: 'Clinic',
      },
      {
        key: 'insurance',
        label: 'Insurance',
      },
      {
        key: 'invoiceId',
        label: 'Invoice ID',
      },
      {
        key: 'dos',
        label: 'Date of Service',
      },
      {
        key: 'pos',
        label: 'POS',
      },
      {
        key: 'cptCodes',
        label: 'CPT Codes',
        width: 150,
      },
      {
        key: 'note',
        label: 'Note',
        align: 'center',
      },
      {
        key: 'invoiceAmount',
        label: 'Invoice Amount',
        align: 'right',
      },
      {
        key: 'status',
        label: 'Status',
      },
      {
        key: 'issues',
        label: 'Issues',
      },
      {
        key: 'logs',
        label: 'Actions',
        align: 'center',
      },
    ];
  }, []);

  const handleOpenLogs = (claimId: number) => {
    setSelectedClaimId(claimId);
    setIsLogsModalOpen(true);
  };

  const handleCloseLogsModal = () => {
    setIsLogsModalOpen(false);
    setSelectedClaimId(null);
  };

  const handleSelectAll = () => {
    console.log('select all');
  };

  const handleDeselectAll = () => {
    console.log('deselect all');
  };

  const handleRowSelection = (id: number) => {
    const claim = data.find((c) => c.id === id);

    if (!claim?.id) {
      return;
    }

    const updatedSelectedRows = selectedClaimIds.includes(id)
      ? selectedClaimIds.filter((rowId) => rowId !== id)
      : [...selectedClaimIds, id];

    setSelectedClaimIds(updatedSelectedRows);
  };

  const handleOpenPdf = async (claimId: number | null) => {
    if (!claimId) return;

    const url = await new BillingService().fetchSignedUrl(claimId);
    if (url) {
      window.open(url, '_blank');
    } else {
      alert('Failed to fetch the note. Please try again.');
    }
  };

  const tableRows = useMemo(
    () =>
      data.map((row) => ({
        select: (
          <Checkbox
            size='small'
            style={{
              color: row.errors.length === 0 ? StyledTheme.tealDark : StyledTheme.grayLight,
            }}
            disabled={row.errors.length > 0 ? true : false}
            onChange={() => handleRowSelection(row.id)}
          />
        ),
        id: row.id,
        name: [row.billingMember.firstName, row.billingMember.lastName].join(' '),
        clinic: row.billingMember.clinicName,
        insurance: formatInsurance(
          row?.billingMember?.insuranceCompany,
          row?.billingMember?.insuranceCompanyId,
        ),
        invoiceId: (
          <BillingLink
            url={row.invoiceId ? getAperoUrl(`/invoices/${row.invoiceId}`) : null}
            label={row.invoiceId}
            defaultMessage='Invoice not available'
          />
        ),
        dos: formatDate(row.dateOfService),
        pos: row.placeOfService,
        cptCodes: <CodeChipList codes={row.cptCodes.map((code) => ({ code: code.cptCode }))} />,
        note: true ? (
          <Link component='button' onClick={() => handleOpenPdf(row.id)} sx={{ cursor: 'pointer' }}>
            View Note
          </Link>
        ) : (
          <Chip label='Note not available' size='small' icon={<BugReportIcon />} />
        ),
        invoiceAmount: row.invoiceAmount ? `$${row.invoiceAmount}` : '',
        status: (
          <Chip
            label={getCapitalizedWord(
              row.status === RpmStatus.EXPORTED ? RpmStatus.VALID : RpmStatus.INVALID,
            )}
            sx={getStatusChipColor(
              row.status === RpmStatus.EXPORTED ? RpmStatus.VALID : RpmStatus.INVALID,
            )}
            size='small'
          />
        ),
        issues:
          row.errors && row.errors.length > 0 ? (
            <CodeChipList codes={row.errors.map((error) => ({ code: error }))} allowSlice={false} />
          ) : null,
        logs: (
          <Link
            component='button'
            onClick={() => handleOpenLogs(row.id)}
            sx={{ cursor: 'pointer' }}>
            View logs
          </Link>
        ),
      })),
    [data],
  );

  return (
    <Box position='relative'>
      {isLoading && <RpmLoading />}

      <CompactTable
        columns={columns}
        rows={tableRows}
        defaultText='No export results found'
        columnGroups={COLUMN_GROUPS}
        pagination={{
          rowsPerPageOptions: [10, 20, 30, 50],
          rowPerPage: pagination.limit,
          page: pagination.currentPage,
          totalCount: pagination.totalCount,
          onPageChange,
          onRowsPerPageChange,
        }}
      />

      <ClaimLogsModal
        open={isLogsModalOpen}
        onClose={handleCloseLogsModal}
        claimId={selectedClaimId}
      />
    </Box>
  );
};
