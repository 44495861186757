import { Box, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
  BillingPagination,
  DownloadStatus,
  GetNoteExportsParams,
  NoteExport,
} from '../../../interfaces/billing.rpm';
import { IClinic } from '../../../interfaces/clinic';
import { SelectOption } from '../../../interfaces/common';
import { BillingService } from '../../../services';
import { CommonService } from '../../../services/common';
import { RPMFilters } from '../common/RPMFilters';
import { FilterConfiguration, INITIAL_PAGINATION } from '../types/rpms.types';
import { NoteExportsTable } from './NoteExportsTable';

type NoteExportFilters = {
  status: string[];
  clinicIds: string[];
  dateFrom: string[];
  dateTo: string[];
};

const initialFilters: NoteExportFilters = {
  status: [],
  clinicIds: [],
  dateFrom: [],
  dateTo: [],
};

export const NoteExports: React.FC = () => {
  const [exportsData, setExportsData] = useState<NoteExport[]>([]);
  const [pagination, setPagination] = useState<BillingPagination>(INITIAL_PAGINATION);
  const [clinicOptions, setClinicOptions] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState({
    isApplying: false,
    isClearing: false,
    isProcessing: false,
  });

  const fetchClinics = async () => {
    try {
      const clinics = await CommonService.getClinics();
      setClinicOptions(
        clinics.map((clinic: IClinic) => ({
          label: clinic.name,
          value: clinic.id.toString(),
        })),
      );
    } catch (error) {
      console.error('Error fetching clinics:', error);
    }
  };

  const filterConfigurations = useMemo<FilterConfiguration<NoteExportFilters>[]>(
    () => [
      {
        key: 'status',
        label: 'Status',
        options: Object.values(DownloadStatus).map((status) => ({
          value: status as string,
          label: status as string,
        })),
        section: 'Note Exports',
        type: 'select',
      },
      {
        key: 'clinicIds',
        label: 'Clinics',
        options: clinicOptions,
        section: 'Note Exports',
        type: 'select',
        multiple: true,
      },
      {
        key: 'dateFrom',
        label: 'Date From',
        options: [],
        section: 'Note Exports',
        type: 'date',
      },
      {
        key: 'dateTo',
        label: 'Date To',
        options: [],
        section: 'Note Exports',
        type: 'date',
      },
    ],
    [clinicOptions],
  );

  useEffect(() => {
    fetchClinics();
  }, []);

  const transformFilters = (filters: NoteExportFilters): GetNoteExportsParams => {
    return {
      ...(filters.status.length > 0 &&
        filters.status[0] !== '' && {
          status: filters.status[0] as DownloadStatus,
        }),
      ...(filters.clinicIds.length > 0 && {
        clinicIds: filters.clinicIds.map(Number),
      }),
      ...(filters.dateFrom.length > 0 && { dateFrom: filters.dateFrom[0] }),
      ...(filters.dateTo.length > 0 && { dateTo: filters.dateTo[0] }),
      page: pagination.currentPage,
      limit: pagination.limit,
    };
  };

  const fetchExports = async (parameters: GetNoteExportsParams) => {
    setLoading((prev) => ({ ...prev, isApplying: true }));
    try {
      const response = await new BillingService().getNoteExports(parameters);
      setExportsData(response?.data || []);
      setPagination(response?.pagination || INITIAL_PAGINATION);
    } catch (error) {
      console.error('Error fetching exports:', error);
    } finally {
      setLoading((prev) => ({ ...prev, isApplying: false }));
    }
  };

  const handleApplyFilters = async (filters: NoteExportFilters) => {
    const transformedFilters = transformFilters(filters);
    await fetchExports(transformedFilters);
  };

  const handleClearFilters = async () => {
    setLoading((prev) => ({ ...prev, isClearing: true }));
    try {
      await fetchExports({
        page: 1,
        limit: pagination.limit,
      });
    } finally {
      setLoading((prev) => ({ ...prev, isClearing: false }));
    }
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, currentPage: newPage }));
  };

  const handleRowsPerPageChange = (newLimit: number) => {
    setPagination((prev) => ({ ...prev, limit: newLimit, currentPage: 1 }));
  };

  useEffect(() => {
    fetchExports({
      page: pagination.currentPage,
      limit: pagination.limit,
    });
  }, [pagination.currentPage, pagination.limit]);

  return (
    <Box sx={{ p: 2 }}>
      <Box>
        <Typography variant='h6'>Filters</Typography>
      </Box>

      <RPMFilters
        configurations={filterConfigurations}
        onApplyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
        initialFilters={initialFilters}
        loadingStates={loading}
      />

      <NoteExportsTable
        data={exportsData}
        isLoading={loading.isApplying}
        pagination={pagination}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Box>
  );
};
