import { Buffer } from 'buffer';

export const downloadFileFromBase64 = (content: string, fileName: string) => {
  const blob = new Blob([Buffer.from(content, 'base64')]);
  const url: string = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getFileAsBase64 = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });
};
