import moment from 'moment-timezone';

export const getDateStringFromUTCDate = (value: string | Date | null | undefined) => {
  // null, undefined and empty string values should not go through dateFormatter
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (!value) {
    return '';
  }

  if (value instanceof Date) {
    return value.toISOString().substring(0, 10);
  }

  return value.substring(0, 10);
};

export const formatDateStringToMMDDYYYY = (value: string | null) => {
  if (value == null || value === '') {
    return '';
  }
  return moment(value).tz('UTC').format('MM/DD/YYYY');
};

export const formatDateStringToYYYYMMDD = (value: string | null | Date) => {
  return value === null || value === '' ? '' : moment(value).tz('UTC').format('YYYY-MM-DD');
};

export const formatDateStringToMMDDYYYYAndHour = (value: string | null) => {
  if (value === null || value === '') {
    return '';
  }

  return moment(value).format('MM/DD/YYYY h:mm');
};

export const formatDateString = (
  value: string | null,
  format: 'MM/DD/YYYY HH:mm' | 'MM/DD/YYYY HH:mm:ss' = 'MM/DD/YYYY HH:mm',
) => {
  if (value === null || value === '') {
    return '';
  }

  return moment(value).format(format);
};

export const formatDateMMDDYYYY = (date: Date) => {
  return moment(date).tz('UTC').format('MM/DD/YYYY');
};

export const formatDateToDayAndDate = (date: string, timezone: string = 'UTC'): string => {
  return moment.tz(date, timezone).format('ddd D');
};
