import axios from 'axios';
import { AuthData, Profile, Role } from '../interfaces/auth';
import { StorageName } from '../util/constants';

export const AuthService = {
  async signIn(payload: { password: string; phone: string }) {
    const response = await axios.post<AuthData>(
      `${process.env.REACT_APP_PUBLIC_AUTH_BASE_URL}/v1/auth`,
      payload,
    );
    return response;
  },

  setAuth(payload: AuthData) {
    localStorage.setItem(
      StorageName.AUTH,
      JSON.stringify({
        id: payload.user_id,
        auth_token: payload.auth_token,
        roles: payload.roles,
      }),
    );
  },

  getAuth() {
    if (!localStorage.getItem(StorageName.AUTH)) {
      localStorage.removeItem(StorageName.AUTH);
      return undefined;
    }
    const auth = localStorage.getItem(StorageName.AUTH) as string;
    const Authorization = JSON.parse(auth);

    if (!Authorization) {
      return undefined;
    }

    return {
      user_id: Authorization.id,
      auth_token: Authorization.auth_token,
      roles: Authorization.roles,
    };
  },
  async getProfile(token: string) {
    const profile = await axios.get<Profile>(
      `${process.env.REACT_APP_PUBLIC_AUTH_BASE_URL}/v1/profile/${token}`,
    );
    return profile.data;
  },
  setProfile(payload: Profile) {
    const {
      user: { id, first_name, last_name, email, uuid },
    } = payload;
    localStorage.setItem(
      StorageName.PROFILE,
      JSON.stringify({
        id,
        fullName: `${first_name} ${last_name}`,
        email,
        uuid,
      }),
    );
  },
  setRoles(roles: Role[]) {
    localStorage.setItem(StorageName.ROLES, JSON.stringify(roles));
  },
  checkRoleAccess(roleToCheck: string): boolean {
    if (!localStorage.getItem(StorageName.ROLES)) {
      localStorage.removeItem(StorageName.ROLES);
      return false;
    }

    try {
      const roles = localStorage.getItem(StorageName.ROLES) as string;
      const parseRoles = JSON.parse(roles);

      if (!parseRoles) {
        return false;
      }

      const hasAccess = parseRoles?.find((rol: Role) => rol?.name === roleToCheck) ? true : false;
      return hasAccess;
    } catch (e) {
      return false;
    }
  },
};
