import { Title } from 'react-admin';
import { ProcessesPanel } from './common/ProcessesPanel';

export const RPMs: React.FC = () => {
  return (
    <>
      <Title title='RPMs' />
      <ProcessesPanel />
    </>
  );
};
