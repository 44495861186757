import { Route } from 'react-router-dom';
import { AuditLogs } from '../components/audit-logs/AuditLogs';
import { BillingExporter } from '../components/billing/BillingExporter';
import { Claims } from '../components/claims/Claims';
import SingleClaim from '../components/claims/SingleClaim';
import { ACEImporter } from '../components/eligibility/ace/ACEImporter';
import { InsurancePlansCreateOrEdit } from '../components/eligibility/insurancePlans/InsurancePlans';
import { PayersCreate } from '../components/eligibility/payers/Payers/PayersCreate';
import { PayersUpdate } from '../components/eligibility/payers/Payers/PayersUpdate';
import { TaskShowUpdate } from '../components/eligibility/tasks/Tasks';
import { MedicalSetup } from '../components/medical-setup/MedicalSetup';
import { CreateCondition } from '../components/medical-setup/tabs/Conditions/CreateCondition';
import { EditCondition } from '../components/medical-setup/tabs/Conditions/EditCondition';
import { ViewCondition } from '../components/medical-setup/tabs/Conditions/ViewCondition';
import { CreateLaboratoryTest } from '../components/medical-setup/tabs/LaboratoryTests/CreateLaboratoryTest';
import { EditLaboratoryTest } from '../components/medical-setup/tabs/LaboratoryTests/EditLaboratoryTest';
import { ViewLaboratoryTest } from '../components/medical-setup/tabs/LaboratoryTests/ViewLaboratoryTest';
import { CreateMedication } from '../components/medical-setup/tabs/Medications/CreateMedication';
import { EditMedication } from '../components/medical-setup/tabs/Medications/EditMedication';
import { ViewMedication } from '../components/medical-setup/tabs/Medications/ViewMedication';
import { CreateProcedure } from '../components/medical-setup/tabs/Procedures/CreateProcedure';
import { EditProcedure } from '../components/medical-setup/tabs/Procedures/EditProcedure';
import { ViewProcedure } from '../components/medical-setup/tabs/Procedures/ViewProcedure';
import { EditPatientCondition } from '../components/patient/conditions/EditPatientCondition';
import { ViewPatientCondition } from '../components/patient/conditions/ViewPatientCondition';
import AddLaboratoryResult from '../components/patient/laboratory-results/AddLaboratoryResult';
import EditLaboratoryResult from '../components/patient/laboratory-results/EditLaboratoryResult';
import LaboratoryResultManualInput from '../components/patient/laboratory-results/LaboratoryResultManualInput';
import LaboratoryResultParseDocumentInput from '../components/patient/laboratory-results/LaboratoryResultParseDocumentInput';
import LaboratoryResults from '../components/patient/laboratory-results/LaboratoryResults';
import { Patient } from '../components/patient/Patient';
import { ReferralList, ReferralShow } from '../components/referrals/Referral';
import { ReferralsUploader } from '../components/referrals/ReferralsUploader';
import { ExportedNotesList } from '../components/rpms/exported-notes-list/ExportedNotesList';
import { RPMs } from '../components/rpms/Rpms';

export const customRoutes = (
  showPatientLabResults: boolean,
  showMedicalSetup: boolean,
  showPatientConditions: boolean,
  showAuditLogs: boolean,
) => [
  <Route exact path='/billing-export' component={BillingExporter} />,
  <Route exact path='/referrals' component={ReferralList} />,
  <Route exact path='/referrals/:referralId/show' component={ReferralShow} />,
  <Route exact path='/referrals/upload' component={ReferralsUploader} />,
  <Route
    exact
    path='/eligibility/insurance-plans/:insuranceId/show'
    component={InsurancePlansCreateOrEdit}
  />,
  <Route
    exact
    path='/eligibility/insurance-plans/payers/:payerId/create'
    component={InsurancePlansCreateOrEdit}
  />,
  <Route exact path='/eligibility/insurance-plans/create' component={InsurancePlansCreateOrEdit} />,
  <Route exact path='/eligibility/tasks/:leadId/show' component={TaskShowUpdate} />,
  <Route exact path='/eligibility/tasks/create' component={TaskShowUpdate} />,
  <Route exact path='/eligibility/payers/:payerId/show' component={PayersUpdate} />,
  <Route exact path='/eligibility/payers/create' component={PayersCreate} />,
  <Route exact path='/ace/import' component={ACEImporter} />,
  <Route exact path='/claims' component={Claims} />,
  <Route exact path='/claims/:claimId/show' component={SingleClaim} />,
  <Route exact path='/rpms' component={RPMs} />,
  <Route exact path='/rpms/exported-notes-list' component={ExportedNotesList} />,
  ...(showMedicalSetup
    ? [
        <Route exact path='/medical-setup' component={MedicalSetup} />,
        <Route exact path='/medical-setup/conditions' component={MedicalSetup} />,
        <Route
          exact
          path='/medical-setup/conditions/create'
          component={() => (
            <MedicalSetup>
              <CreateCondition />
            </MedicalSetup>
          )}
        />,
        <Route
          exact
          path='/medical-setup/conditions/:conditionId/show'
          component={() => (
            <MedicalSetup>
              <ViewCondition />
            </MedicalSetup>
          )}
        />,
        <Route
          exact
          path='/medical-setup/conditions/:conditionId/edit'
          component={() => (
            <MedicalSetup>
              <EditCondition />
            </MedicalSetup>
          )}
        />,
        <Route exact path='/medical-setup/procedures' component={MedicalSetup} />,
        <Route
          exact
          path='/medical-setup/procedures/create'
          component={() => (
            <MedicalSetup>
              <CreateProcedure />
            </MedicalSetup>
          )}
        />,
        <Route
          exact
          path='/medical-setup/procedures/:procedureId/show'
          component={() => (
            <MedicalSetup>
              <ViewProcedure />
            </MedicalSetup>
          )}
        />,
        <Route
          exact
          path='/medical-setup/procedures/:procedureId/edit'
          component={() => (
            <MedicalSetup>
              <EditProcedure />
            </MedicalSetup>
          )}
        />,
        <Route exact path='/medical-setup/laboratory-tests' component={MedicalSetup} />,
        <Route
          exact
          path='/medical-setup/laboratory-tests/create'
          component={() => (
            <MedicalSetup>
              <CreateLaboratoryTest />
            </MedicalSetup>
          )}
        />,
        <Route
          exact
          path='/medical-setup/laboratory-tests/:laboratoryTestId/show'
          component={() => (
            <MedicalSetup>
              <ViewLaboratoryTest />
            </MedicalSetup>
          )}
        />,
        <Route
          exact
          path='/medical-setup/laboratory-tests/:laboratoryTestId/edit'
          component={() => (
            <MedicalSetup>
              <EditLaboratoryTest />
            </MedicalSetup>
          )}
        />,
        <Route exact path='/medical-setup/medications' component={MedicalSetup} />,
        <Route
          exact
          path='/medical-setup/medications/create'
          component={() => (
            <MedicalSetup>
              <CreateMedication />
            </MedicalSetup>
          )}
        />,
        <Route
          exact
          path='/medical-setup/medications/:medicationId/show'
          component={() => (
            <MedicalSetup>
              <ViewMedication />
            </MedicalSetup>
          )}
        />,
        <Route
          exact
          path='/medical-setup/medications/:medicationId/edit'
          component={() => (
            <MedicalSetup>
              <EditMedication />
            </MedicalSetup>
          )}
        />,
      ]
    : []),

  ...(showPatientConditions
    ? [
        <Route exact path='/patients/conditions' component={Patient} />,
        <Route exact path='/patients' component={Patient} />,
        <Route
          exact
          path='/patients/:patientId/conditions/edit'
          component={() => (
            <Patient>
              <EditPatientCondition />
            </Patient>
          )}
        />,
        <Route
          exact
          path='/patients/:patientId/conditions/show'
          component={() => (
            <Patient>
              <ViewPatientCondition />
            </Patient>
          )}
        />,
      ]
    : []),
  ...(showPatientLabResults
    ? [
        <Route exact path='/laboratory-results' component={LaboratoryResults} />,
        <Route exact path='/laboratory-results/add' component={AddLaboratoryResult} />,
        <Route
          exact
          path='/laboratory-results/manual-input'
          component={LaboratoryResultManualInput}
        />,
        <Route
          exact
          path='/laboratory-results/parse-document-input'
          component={LaboratoryResultParseDocumentInput}
        />,
        <Route exact path='/laboratory-results/:groupId/edit' component={EditLaboratoryResult} />,
      ]
    : []),
  ...(showAuditLogs ? [<Route exact path='/audit-logs' component={AuditLogs} />] : []),
];
