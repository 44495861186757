import { Autocomplete, AutocompleteChangeReason, TextField } from '@mui/material';
import { FC, useEffect } from 'react';
import { SelectOption } from '../../../../interfaces/common';
import { LaboratoryTest } from '../../../../interfaces/health-data';
import healthDataService from '../../../../services/health-data';

/**
 * Used for visual purpose for now (Our BE also default to this one when not included) - Only code type available for now
 */
export const DEFAULT_CODE_TYPE = 'LOINC';

/**
 * The idea is to fetch all of them at once so it is easier to search/select when editing
 */
const LABORATORY_TESTS_HUGE_PAGE_SIZE = 400;

export const ALL_LABORATORY_TESTS: Record<string, LaboratoryTest> = {};
const ALL_LABORATORY_TESTS_OPTIONS: SelectOption[] = [];

export const preloadLaboratoryTests = async (params?: { force: boolean }): Promise<void> => {
  const { force } = params ?? { force: false };

  if (!force && ALL_LABORATORY_TESTS_OPTIONS.length > 0) {
    return console.log('Laboratory tests already preloaded');
  }

  const response = await healthDataService.findAllLaboratoryTestsByParams({
    pageSize: LABORATORY_TESTS_HUGE_PAGE_SIZE,
  });

  if (response.data) {
    if (force) {
      console.log('Laboratory tests forced to be preloaded');

      ALL_LABORATORY_TESTS_OPTIONS.length = 0;
    }

    for (const labTest of response.data.laboratoryTests) {
      ALL_LABORATORY_TESTS_OPTIONS.push({
        label: `(${DEFAULT_CODE_TYPE}:${labTest.loincCode}) ${labTest.name}`,
        value: labTest.loincCode,
      });

      ALL_LABORATORY_TESTS[labTest.loincCode] = labTest;
    }
  } else {
    console.error('Unable to preload all laboratory tests');
  }
};

type Props = {
  value: string | undefined;
  onChange: (labTest: LaboratoryTest | null) => void;
};

const LaboratoryTestSelector: FC<Props> = ({ value, onChange }) => {
  useEffect(() => {
    if (value && ALL_LABORATORY_TESTS[value]) {
      onChange(ALL_LABORATORY_TESTS[value]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (
    _: React.SyntheticEvent,
    newValue: SelectOption | null,
    reason: AutocompleteChangeReason,
  ): void => {
    if (reason === 'clear' || !newValue) {
      onChange(null);
    } else {
      onChange(ALL_LABORATORY_TESTS[newValue.value]);
    }
  };

  const getSelectedOption = (): SelectOption | null => {
    if (!value) {
      return null;
    }

    const { name, loincCode } = ALL_LABORATORY_TESTS[value];

    return { label: name, value: loincCode };
  };

  return (
    <Autocomplete
      disablePortal
      fullWidth
      onChange={handleOnChange}
      options={ALL_LABORATORY_TESTS_OPTIONS}
      renderInput={(params) => <TextField label='Test Name' {...params} />}
      size='small'
      value={getSelectedOption()}
    />
  );
};

export default LaboratoryTestSelector;
