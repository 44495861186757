import BugReportIcon from '@mui/icons-material/BugReport';
import { Chip, Link } from '@mui/material';

interface BillingLinkProps {
  url?: string | null;
  label?: string;
  defaultMessage?: string;
}

export const BillingLink = ({ url, label = 'View', defaultMessage }: BillingLinkProps) => {
  if (!url) {
    return <Chip label={defaultMessage || 'No content'} size='small' icon={<BugReportIcon />} />;
  }

  return (
    <Link href={url} target='_blank' rel='noopener noreferrer'>
      {label}
    </Link>
  );
};
