import Looks3Icon from '@mui/icons-material/Looks3';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ClaimStage } from '../../../interfaces/billing';
import { BillingProcess } from '../../../interfaces/billing.rpm';
import { CustomTabs } from '../../common/CustomTabs/CustomTabs';
import { TabPanel } from '../../common/TabPanel/TabPanel';
import { RPMExportResults } from '../export-history/RPMExportResults';
import { PatientSelection } from '../patient-selection/PatientSelection';
import { RPMCalculation } from '../rpm-calculation/RPMCalculation';
import { ActionableTab } from './types';

type ClaimSummaryProps = ActionableTab & {
  process?: BillingProcess | null;
};

function getInitialTabIndexFromProcessStatus(process?: BillingProcess | null) {
  const initialTabIndexByProcessStatus = {
    [ClaimStage.Created]: 1,
    [ClaimStage.Exported]: 2,
  };

  if (!process?.status || !initialTabIndexByProcessStatus[process.status]) {
    return 0;
  }

  return initialTabIndexByProcessStatus[process.status];
}

export const ClaimSummary: FC<ClaimSummaryProps> = ({ onAfterAction, process }) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    setTabIndex(getInitialTabIndexFromProcessStatus(process));
  }, [process]);

  return (
    <Box flex={6} sx={{ overflow: 'auto' }}>
      <CustomTabs
        tabLabels={[
          { label: 'Patient Selection', icon: <LooksOneIcon /> },
          { label: 'RPM Calculation', icon: <LooksTwoIcon /> },
          { label: 'RPM Export Results', icon: <Looks3Icon /> },
        ]}
        value={tabIndex}
        onChange={() => {
          // Not allowed to change tab
        }}
        enabledTabs={[tabIndex]}
        aria-label='RPM Process Tabs'
        useEnabledTabs={true}
      />
      <TabPanel value={tabIndex} index={0}>
        <PatientSelection onAfterAction={onAfterAction} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <RPMCalculation onAfterAction={onAfterAction} processId={process?.id} />
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <RPMExportResults processId={process?.id} />
      </TabPanel>
    </Box>
  );
};
