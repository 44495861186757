import { DownloadStatus } from '../../../interfaces/billing.rpm';
import { StyledTheme } from '../../../styles/styleTheme';
import { RpmStatus } from '../types/rpms.types';
import { CodeChipLevel } from './CodeChipList';

export const getStatusChipColor = (status: string) => {
  switch (status) {
    case RpmStatus.VALID:
      return { backgroundColor: StyledTheme.greenMint, color: '#fff' };
    case RpmStatus.INVALID:
      return { backgroundColor: StyledTheme.redLightMui, color: '#fff' };
    default:
      return { backgroundColor: StyledTheme.grayLight, color: '#000' };
  }
};

export const getExportedNoteStatusChipColor = (status: DownloadStatus) => {
  const chipColorByStatus = {
    [DownloadStatus.COMPLETED]: { backgroundColor: StyledTheme.greenMint, color: '#fff' },
    [DownloadStatus.FAILED]: { backgroundColor: StyledTheme.redLightMui, color: '#fff' },
    [DownloadStatus.PENDING]: { backgroundColor: '#fff3e0', color: '#ed6c02' },
  };

  return chipColorByStatus[status] ? chipColorByStatus[status] : {};
};

export const getDetailChipColor = (level: CodeChipLevel) => {
  const styleDefinition: { [key in CodeChipLevel]: { backgroundColor: string; color: string } } = {
    error: { backgroundColor: '#F8D7DA', color: '#721C24' },
    info: { backgroundColor: '#E2E3E5', color: '#383D41' },
    warning: { backgroundColor: '#FFF3CD', color: '#856404' },
  };

  return styleDefinition[level] ?? styleDefinition['info'];
};

export const getLogChipColor = (status: string) => {
  switch (status.toUpperCase()) {
    case 'ERROR':
      return { backgroundColor: '#fdecea', color: '#d32f2f' };
    case 'WARNING':
      return { backgroundColor: '#fff3e0', color: '#ed6c02' };
    case 'INFO':
      return { backgroundColor: '#e3f2fd', color: '#1976d2' };
    case 'DEBUG':
      return { backgroundColor: '#f5f5f5', color: '#757575' };
    default:
      return { backgroundColor: '#f5f5f5', color: '#757575' };
  }
};
