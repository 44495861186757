import CloseIcon from '@mui/icons-material/Close';
import { Box, Chip, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BillingPagination, ClaimLog } from '../../../interfaces/billing.rpm';
import { BillingService } from '../../../services';
import { formatDateString } from '../../../util/dateFormat';
import { getCapitalizedWord } from '../../../util/stringFormat';
import { CompactTable } from '../../common/CompactTable/CompactTable';
import { Column } from '../../common/CustomTable';
import { INITIAL_PAGINATION } from '../types/rpms.types';
import { getLogChipColor } from './ChipColors';
import { RpmLoading } from './RpmLoading';

interface ClaimLogsModalProps {
  open: boolean;
  onClose: () => void;
  claimId: number | null;
}

const CLAIM_LOG_COLUMNS: Column[] = [
  {
    key: 'date',
    label: 'Date',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'stage',
    label: 'Stage',
  },
  {
    key: 'description',
    label: 'Description',
  },
];

export const ClaimLogsModal: React.FC<ClaimLogsModalProps> = ({ open, onClose, claimId }) => {
  const [logs, setLogs] = useState<ClaimLog[]>([]);
  const [pagination, setPagination] = useState<BillingPagination>(INITIAL_PAGINATION);
  const [isLoading, setIsLoading] = useState(false);

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, currentPage: newPage }));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLimit = parseInt(event.target.value, 10);
    setPagination({ ...INITIAL_PAGINATION, limit: newLimit });
  };

  const tableRows = useMemo(
    () =>
      logs.map((log) => ({
        date: formatDateString(log.date, 'MM/DD/YYYY HH:mm:ss'),
        status: (
          <Chip
            label={getCapitalizedWord(log.status)}
            size='small'
            sx={{ ...getLogChipColor(log.status) }}
          />
        ),
        stage: getCapitalizedWord(log.stage),
        description: log.description || '-',
      })),
    [logs],
  );

  const fetchLogs = useCallback(async () => {
    try {
      if (!claimId) return;

      setIsLoading(true);
      const response = await new BillingService().getClaimLogs(claimId, {
        page: pagination.currentPage,
        limit: pagination.limit,
      });

      setLogs(response?.data || []);

      if (response?.pagination) {
        setPagination((prev) => ({
          ...prev,
          totalCount: response.pagination.totalCount,
          totalPages: response.pagination.totalPages,
        }));
      } else {
        setPagination(INITIAL_PAGINATION);
      }
    } catch (error) {
      console.error('Error fetching claim logs:', error);
    } finally {
      setIsLoading(false);
    }
  }, [claimId, pagination.currentPage, pagination.limit]);

  useEffect(() => {
    if (open) {
      setPagination(INITIAL_PAGINATION);
    }
  }, [open]);

  useEffect(() => {
    if (open && claimId) {
      fetchLogs();
    }
  }, [open, claimId, fetchLogs]);

  if (!claimId) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontSize: '1.5rem',
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        Claim Logs
        <IconButton onClick={onClose} sx={{ color: 'inherit' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box position='relative'>
          {isLoading ? (
            <RpmLoading />
          ) : (
            <CompactTable
              columns={CLAIM_LOG_COLUMNS}
              rows={tableRows}
              defaultText='No logs found'
              pagination={{
                rowsPerPageOptions: [10, 20, 30, 50],
                rowPerPage: pagination.limit,
                page: pagination.currentPage,
                totalCount: pagination.totalCount,
                onPageChange: handlePageChange,
                onRowsPerPageChange: handleChangeRowsPerPage,
              }}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
