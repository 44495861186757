import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { StyledTheme } from '../../../styles/styleTheme';

interface DiscardCalculationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

export const DiscardCalculationModal: React.FC<DiscardCalculationModalProps> = ({
  open,
  onClose,
  onConfirm,
  isLoading = false,
}) => {
  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>Discard RPM Calculation Results?</DialogTitle>
      <DialogContent>
        <Typography>
          You're about to discard the whole process along with its associated claims. This action
          cannot be reversed. Are you sure you want to proceed?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='outlined' disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant='contained'
          color='primary'
          disabled={isLoading}
          style={{ backgroundColor: StyledTheme.tealDark }}>
          {isLoading ? <CircularProgress size={24} color='inherit' /> : 'Discard Calculation'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
