import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { RPMNoteContent } from '../../../interfaces/billing.rpm';
import { BillingService } from '../../../services';
import { formatDateStringToMMDDYYYY } from '../../../util/dateFormat';
import { SectionContent } from './SectionContent';

interface NotePreviewModalProps {
  open: boolean;
  onClose: () => void;
  claimId: number;
}

export const RPMNotePreviewModal: FC<NotePreviewModalProps> = ({ open, onClose, claimId }) => {
  const [note, setNote] = useState<RPMNoteContent | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!open) {
      setNote(null);
      setError(null);
    }
  }, [open]);

  useEffect(() => {
    const fetchNote = async () => {
      if (!claimId || !open) return;

      setIsLoading(true);
      setError(null);

      try {
        const billingService = new BillingService();
        const response = await billingService.getRpmNote(claimId);

        if (!response) {
          setError('Failed to load note data');
        } else {
          setNote(response.note);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Error fetching note');
      } finally {
        setIsLoading(false);
      }
    };

    if (open) {
      fetchNote();
    }
  }, [claimId, open]);

  if (!claimId) return null;

  const handleClose = () => {
    setNote(null);
    setError(null);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
      // Add cleanup on exit
      TransitionProps={{
        onExited: () => {
          setNote(null);
          setError(null);
        },
      }}>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontSize: '1.5rem',
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        Draft RPM Note
        <IconButton onClick={handleClose} sx={{ color: 'inherit' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading && (
          <Box display='flex' justifyContent='center' alignItems='center' height={200}>
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Typography color='error' align='center'>
            {error}
          </Typography>
        )}
        {note && (
          <Box>
            <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Patient:</strong> {note.header.patient}
                  </Typography>
                  <Typography>
                    <strong>DOB:</strong> {formatDateStringToMMDDYYYY(note.header.dateOfBirth)}
                  </Typography>
                  <Typography>
                    <strong>Sex:</strong> {note.header.sex}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>
                    <strong>Chart #:</strong> {note.header.chartNumber}
                  </Typography>
                  <Typography>
                    <strong>Provider:</strong> {note.header.provider}
                  </Typography>
                  <Typography>
                    <strong>Visit Date:</strong> {formatDateStringToMMDDYYYY(note.header.visitDate)}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            {note.sections
              .sort((a, b) => a.order - b.order)
              .map((section) => (
                <Box key={section.key} sx={{ mb: 3 }}>
                  <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 1 }}>
                    {section.title}
                  </Typography>
                  <SectionContent section={section} />
                  <Divider sx={{ mt: 2 }} />
                </Box>
              ))}

            <Box sx={{ mt: 4, textAlign: 'center', color: 'text.secondary' }}>
              <Typography>{note.footer.centerText}</Typography>
              <Typography variant='caption'>
                {note.footer.datetime} - {note.footer.providerFullName}
              </Typography>
              <Typography variant='caption' display='block'>
                {note.footer.poweredBy}
              </Typography>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
