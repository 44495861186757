import axios from 'axios';
import {
  AperoSyncClinicsResponse,
  BillingTransform,
  EHRClaimSync,
  EHRClaimSyncRequestPayload,
  EHRClaimSyncRequestResults,
  EHRSyncPayload,
  EhrClaimResponse,
  EhrClaimResyncPayload,
  EhrClaimsResponse,
  RpmCalculationFilters,
  RpmExportedFilters,
  RpmPatientSelectionFilters,
} from '../interfaces/billing';
import {
  BillingClaim,
  BillingClaimLogResponse,
  BillingClaimsExportedResponse,
  BillingClaimsPreviewResponse,
  BillingNoteResponse,
  BillingPatientsResponse,
  BillingProcess,
  BillingUser,
  GetNoteExportsParams,
  NoteExportsResponse,
} from '../interfaces/billing.rpm';
import { AuthService } from './auth';

const ENARA_API_URL = process.env.REACT_APP_ENARA_BASE_URL;
const BILLING_API_URL = process.env.REACT_APP_PUBLIC_BILLING_API_BASE_URL;

export class BillingService {
  public async getTransformedFile(
    data: FormData,
    clinicId: number,
    isHL7: boolean,
  ): Promise<BillingTransform[] | null> {
    const authData = AuthService.getAuth();
    try {
      const response = await axios.post(`${ENARA_API_URL}/billing/billing/v1/transform`, data, {
        headers: {
          Authorization: `Bearer ${authData?.auth_token}`,
          'content-type': 'multipart/form-data',
          'Clinic-Id': `${clinicId}`,
          ...(isHL7 && { Hl7: `${isHL7}` }),
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  public async runEhrClaimSync(payload: EHRSyncPayload): Promise<AperoSyncClinicsResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.post<AperoSyncClinicsResponse>(
        `${ENARA_API_URL}/billing/billing/v1/ehr-claims-syncs`,
        payload,
        { headers: { Authorization: `Bearer ${authData?.auth_token}` } },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async reSyncEhrClaim(
    claimId: number,
    payload?: EhrClaimResyncPayload,
  ): Promise<EhrClaimResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.post<EhrClaimResponse>(
        `${ENARA_API_URL}/billing/billing/v1/ehr-claims/${claimId}/syncs`,
        payload,
        { headers: { Authorization: `Bearer ${authData?.auth_token}` } },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getClaimSyncRequests(
    payload: EHRClaimSyncRequestPayload,
  ): Promise<EHRClaimSyncRequestResults | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get(
        `${ENARA_API_URL}/billing/billing/v1/ehr-claim-sync-requests`,
        {
          headers: { Authorization: `Bearer ${authData?.auth_token}` },
          params: payload,
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getClaims(payload: EHRClaimSync): Promise<EhrClaimsResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get(`${ENARA_API_URL}/billing/billing/v1/ehr-claims-syncs`, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
        params: payload,
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getClaim(id: number): Promise<EhrClaimResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get(`${ENARA_API_URL}/billing/billing/v1/ehr-claims/${id}`, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getPatients({
    clinicIds = [],
    optOutStatus = [],
    icd10Codes = [],
    insuranceCompanyIds = [],
    cptCodes = [],
    rpmStatuses = [],
    memberIds = [],
    limit = 10,
    page = 1,
  }: RpmPatientSelectionFilters): Promise<BillingPatientsResponse | null> {
    const authData = AuthService.getAuth();

    try {
      // removing empty array and empty items.
      const parameters = this.prepareParameters({
        clinicIds,
        optOutStatus,
        icd10Codes,
        insuranceCompanyIds,
        cptCodes,
        rpmStatuses,
        memberUuids: memberIds,
        limit,
        page,
      });

      const response = await axios.get<BillingPatientsResponse>(`${BILLING_API_URL}/patients`, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
        params: parameters,
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async generateClaimsBulk(memberUuids: string[], user: BillingUser) {
    const authData = AuthService.getAuth();

    try {
      const body = {
        claims: memberUuids.map((memberUuid) => ({ memberUuid })),
        user,
      };

      const response = await axios.post<BillingClaim[]>(`${BILLING_API_URL}/claims/bulk`, body, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async generateClaimsBulkWithFilters(
    filters: Partial<{
      clinicIds: number[];
      icd10Codes: string[];
      insuranceCompanyIds: string[];
    }>,
    user: BillingUser,
    memberUuidsToExclude?: string[],
  ) {
    const authData = AuthService.getAuth();

    try {
      const body = {
        user,
        ...filters,
        ...(memberUuidsToExclude?.length && {
          exclude: memberUuidsToExclude.map((memberUuid) => ({ memberUuid })),
        }),
      };

      const response = await axios.post<BillingClaim[]>(
        `${BILLING_API_URL}/claims/bulk-with-filters`,
        body,
        {
          headers: { Authorization: `Bearer ${authData?.auth_token}` },
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async exportClaims(claimIds: number[], user: BillingUser) {
    const authData = AuthService.getAuth();

    try {
      const body = {
        claims: claimIds.map((id) => ({ id })),
        user,
      };

      const response = await axios.post(`${BILLING_API_URL}/claims/export`, body, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async exportClaimsWithFilters(
    filters: Partial<{
      clinicIds: number[];
      insuranceCompanyIds: string[];
      dateOfService: string;
      placeOfService: string[];
      cptCodes: string[];
      memberUuids: string[];
    }>,
    user: BillingUser,
    claimIdsToExclude?: number[],
  ) {
    const authData = AuthService.getAuth();

    try {
      const body = this.prepareParameters({
        user,
        ...filters,
        exclude: claimIdsToExclude,
      });

      const response = await axios.post(`${BILLING_API_URL}/claims/export-with-filters`, body, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getClaimsCalculated(
    parameters: RpmCalculationFilters,
  ): Promise<BillingClaimsPreviewResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const finalParameters = this.prepareParameters(parameters);

      const response = await axios.get<BillingClaimsPreviewResponse>(
        `${BILLING_API_URL}/claims/preview`,
        {
          headers: { Authorization: `Bearer ${authData?.auth_token}` },
          params: finalParameters,
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getExportedClaims(filters: RpmExportedFilters) {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get<BillingClaimsExportedResponse>(
        `${BILLING_API_URL}/claims/exported`,
        {
          headers: { Authorization: `Bearer ${authData?.auth_token}` },
          params: filters,
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async discardClaims(processId: number, user: BillingUser) {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.patch(
        `${BILLING_API_URL}/claims/discard`,
        { processId, user },
        {
          headers: { Authorization: `Bearer ${authData?.auth_token}` },
        },
      );

      if (response.status !== 200) {
        const errorMessage = `Can't discard claims linked to process ID=${processId}`;
        console.log(errorMessage);
        throw new Error(errorMessage);
      }

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private prepareParameters(input: Record<string, any>) {
    return Object.fromEntries(
      Object.entries(input).filter(([_, value]) => {
        if (Array.isArray(value)) {
          if (value.length === 0) {
            return false;
          }

          return value.every((item) => item !== undefined && item !== null && item !== '');
        }

        return value !== undefined;
      }),
    );
  }

  public async getRpmNote(claimId: number): Promise<BillingNoteResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get<BillingNoteResponse>(
        `${BILLING_API_URL}/claims/${claimId}/note`,
        {
          headers: { Authorization: `Bearer ${authData?.auth_token}` },
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async fetchSignedUrl(claimId: number): Promise<string | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get<{ url: string }>(
        `${BILLING_API_URL}/claims/${claimId}/note/pdf`,
        {
          headers: { Authorization: `Bearer ${authData?.auth_token}` },
        },
      );

      return response.data.url;
    } catch (error) {
      console.error(`Error fetching signed URL for claim ${claimId}:`, error);
      return null;
    }
  }

  public async getClaimLogs(
    claimId: number,
    pagination: { page: number; limit: number },
  ): Promise<BillingClaimLogResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get<BillingClaimLogResponse>(
        `${BILLING_API_URL}/claims/${claimId}/logs`,
        {
          headers: { Authorization: `Bearer ${authData?.auth_token}` },
          params: pagination,
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getProcesses(): Promise<BillingProcess[] | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get<BillingProcess[]>(`${BILLING_API_URL}/processes`, {
        headers: { Authorization: `Bearer ${authData?.auth_token}` },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async getNoteExports(params?: GetNoteExportsParams): Promise<NoteExportsResponse | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get<NoteExportsResponse>(`${BILLING_API_URL}/notes/exports`, {
        params,
        headers: {
          Authorization: `Bearer ${authData?.auth_token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching note exports:', error);
      return null;
    }
  }

  public async getNoteExportDownloadUrl(exportId: number): Promise<string | null> {
    const authData = AuthService.getAuth();

    try {
      const response = await axios.get<{ url: string }>(
        `${BILLING_API_URL}/notes/exports/${exportId}/download-url`,
        {
          headers: {
            Authorization: `Bearer ${authData?.auth_token}`,
          },
        },
      );

      return response.data.url;
    } catch (error) {
      console.error(`Error fetching pre-signed URL for exportId ${exportId}:`, error);
      return null;
    }
  }
}
