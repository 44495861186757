import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { StyledTheme } from '../../../styles/styleTheme';

interface ProcessClaimsModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  claimsCount: number;
  isLoading?: boolean;
  allClaimsSelected?: boolean;
}

export const ProcessClaimsModal: React.FC<ProcessClaimsModalProps> = ({
  open,
  onClose,
  onConfirm,
  claimsCount,
  isLoading = false,
  allClaimsSelected = false,
}) => {
  return (
    <Dialog open={open} disableEscapeKeyDown maxWidth='sm' fullWidth>
      <DialogTitle>Process Claims?</DialogTitle>
      <DialogContent>
        <Typography>
          You are about to process {allClaimsSelected ? 'all the selected' : claimsCount} claims.
          This action will generate and submit claims to Apero Health's system.
        </Typography>
        <Typography sx={{ mt: 2, fontWeight: 'medium' }}>
          Please confirm that you want to proceed with this export.
        </Typography>

        <Typography
          variant='body2'
          color='text.secondary'
          sx={{
            mt: 2,
            fontStyle: 'italic',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
          <InfoIcon sx={{ fontSize: 16 }} />
          Claims from Mankato clinic are excluded from this process
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='outlined' disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant='contained'
          style={{ backgroundColor: StyledTheme.tealDark }}
          disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} color='inherit' /> : 'Process Claims'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
