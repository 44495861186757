import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';

export const MENU_ITEMS = {
  rpm: {
    id: 'rpm',
    path: '/rpms',
    label: 'RPM',
    icon: <MonitorHeartIcon />,
    children: [
      {
        id: 'rpm-core',
        path: '/rpms',
        label: 'Core Process',
        icon: <AccountTreeIcon />,
      },
      {
        id: 'rpm-export',
        path: '/rpms/exported-notes-list',
        label: 'Notes Export List',
        icon: <FolderZipIcon />,
      },
    ],
  },
};
