import { Title } from 'react-admin';
import { NoteExports } from './NoteExports';

export const ExportedNotesList: React.FC = () => {
  return (
    <>
      <Title title='Exported Notes List' />
      <NoteExports />
    </>
  );
};
