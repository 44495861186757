import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { ChangeEvent, FC, useRef, useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import healthDataService from '../../../services/health-data';
import { Loader } from '../../common/Loader';
import '../../common/tab-section.css';
import LinkableBreadcrumb from './LinkableBreadcrumb';

const AddLaboratoryResult: FC = () => {
  const [processingFile, setProcessingFile] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<globalThis.File | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const redirect = useRedirect();
  const notify = useNotify();

  const handleFileSelection = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { files } = e.target;

    if (!files) {
      return;
    }

    setSelectedFile(files[0]);
  };

  const handleUploadFile = async (): Promise<void> => {
    setProcessingFile(true);

    const labs = await healthDataService.processInboundLaboratoryResultsDocument(
      selectedFile!,
      selectedFile!.name,
    );

    if (labs) {
      redirect('/laboratory-results/parse-document-input', undefined, undefined, undefined, {
        labs,
      });
    } else {
      notify(
        'We were unable to read and extract laboratory results from the selected file',
        'error',
      );

      setProcessingFile(false);
    }
  };

  return (
    <>
      <Box mt={2} mb={2}>
        <LinkableBreadcrumb
          items={[
            { text: 'laboratory results', location: '/laboratory-results' },
            { text: 'add new lab' },
          ]}
        />
      </Box>

      <Box className='tab-section laboratory-tests-section' mt={0}>
        <Box className='laboratory-tests-header'>
          <Typography>Add New Lab Results</Typography>

          <Box style={{ borderTop: '1px solid #e0e0e0' }} />

          {processingFile ? (
            <Loader />
          ) : (
            <Stack gap={4} width={300}>
              <Button
                sx={{ textTransform: 'capitalize' }}
                component='label'
                variant='outlined'
                onClick={() => inputRef.current?.click()}
                fullWidth>
                Select File
              </Button>

              {selectedFile && (
                <Box display='flex' flexDirection='row' alignItems='center' gap={1}>
                  <strong>Selected File:</strong>
                  <Typography>{selectedFile.name}</Typography>
                  <IconButton
                    style={{ width: 20 }}
                    size='small'
                    onClick={() => setSelectedFile(null)}>
                    <DeleteIcon color='action' />
                  </IconButton>
                </Box>
              )}

              <input
                ref={inputRef}
                type='file'
                accept={'application/pdf'}
                onChange={handleFileSelection}
                hidden
              />

              <Button
                sx={{ textTransform: 'capitalize' }}
                component='label'
                variant='contained'
                disabled={!selectedFile}
                fullWidth
                onClick={handleUploadFile}>
                Upload and process File
              </Button>

              <Typography>Or</Typography>

              <Button
                sx={{ textTransform: 'capitalize' }}
                component='label'
                variant='outlined'
                fullWidth
                onClick={() => redirect('/laboratory-results/manual-input')}>
                Manual Input
              </Button>
            </Stack>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AddLaboratoryResult;
