import { Box } from '@mui/material';
import { CustomTable } from '../CustomTable/CustomTable';
import { CustomTableProps } from '../CustomTable/types';
import {
  StyledTable,
  StyledTableCell,
  StyledTableContainer,
  StyledTablePagination,
  StyledTableRow,
} from './styles';

interface CompactTableProps extends CustomTableProps {
  border?: boolean;
}

export const CompactTable = ({
  columns,
  rows,
  minWidth = 600,
  defaultText = 'No data available',
  border = true,
  pagination,
  columnGroups = [],
}: CompactTableProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <CustomTable
        columns={columns}
        rows={rows}
        minWidth={minWidth}
        defaultText={defaultText}
        pagination={pagination}
        columnGroups={columnGroups}
        components={{
          TableContainer: StyledTableContainer,
          Table: (props) => (
            <StyledTable
              {...props}
              sx={{ ...props.sx, border: border ? '1px solid #e0e0e0' : 'none' }}
            />
          ),
          TableCell: StyledTableCell,
          TableRow: StyledTableRow,
          TablePagination: (props) => (
            <StyledTablePagination
              {...props}
              sx={{
                ...props.sx,
                border: border ? '1px solid #e0e0e0' : 'none',
              }}
            />
          ),
        }}
        stickyHeader
        stickyPagination
        maxHeight={600}
      />
    </Box>
  );
};
