import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List as MuiList,
  styled,
} from '@mui/material';
import React, { FC } from 'react';
import { MenuItemLink } from 'react-admin';
import { useLocation } from 'react-router-dom';
import MixpanelService from '../services/mixpanel';
import { StyledTheme } from '../styles/styleTheme';
import { theme } from '../styles/theme';
import { MenuItem } from './types';

const StyledCollapse = styled(Collapse)(({ theme }) => ({
  '& .MuiList-root': {
    paddingLeft: theme.spacing(4),
    borderLeft: `1px solid ${theme.palette.divider}`,
    marginLeft: theme.spacing(2),
  },
  '& .MuiListItemButton-root': {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: '40px',
  },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  '&.active': {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.primary,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

interface RPMMenuProps {
  item: MenuItem;
  open: boolean;
  onToggle: () => void;
}

export const RPMMenu: FC<RPMMenuProps> = ({ item, open, onToggle }) => {
  const { pathname } = useLocation();

  const isActive = pathname.startsWith(item.path);

  return (
    <>
      <StyledListItemButton onClick={onToggle} className={isActive ? 'active' : ''}>
        <ListItemIcon
          sx={{
            color: isActive ? StyledTheme.tealDark : 'text.secondary',
            minWidth: '40px',
          }}>
          {item.icon}
        </ListItemIcon>
        <ListItemText primary={item.label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </StyledListItemButton>

      <StyledCollapse in={open} timeout='auto' unmountOnExit>
        <MuiList component='div' disablePadding>
          {(item.children ?? []).map((child) => {
            const isChildActive = pathname === child.path;

            const styledIcon = React.cloneElement(child.icon, {
              style: {
                color: isChildActive ? theme.palette.primary.main : theme.palette.text.secondary,
              },
            });

            return (
              <MenuItemLink
                key={child.id}
                to={child.path}
                primaryText={child.label}
                leftIcon={styledIcon}
                style={{
                  paddingLeft: theme.spacing(2),
                  color: isChildActive ? theme.palette.primary.main : theme.palette.text.secondary,
                  backgroundColor: isChildActive ? theme.palette.action.selected : 'transparent',
                }}
                onClick={() =>
                  MixpanelService.trackButtonClicked({
                    Field: child.label,
                    source: pathname,
                    Type: 'link',
                  })
                }
              />
            );
          })}
        </MuiList>
      </StyledCollapse>
    </>
  );
};
