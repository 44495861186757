import { FC } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { LaboratoryResultRecordSource } from '../../../interfaces/health-data';
import healthDataService from '../../../services/health-data';
import '../../common/tab-section.css';
import LaboratoryResultsForm, { OnSaveChangesArgs } from './Form/LaboratoryResultsForm';

const LaboratoryResultManualInput: FC = () => {
  const redirect = useRedirect();
  const notify = useNotify();

  const handleSaveChanges = async (args: OnSaveChangesArgs): Promise<void> => {
    const { externalDataSource, patient, resultsOn, labResults } = args;

    if (
      await healthDataService.createLaboratoryResults({
        recordSource: LaboratoryResultRecordSource.ManualInput,
        externalDataSource,
        patient,
        resultsOn: new Date(resultsOn).toISOString(),
        labResults,
      })
    ) {
      return redirect('/laboratory-results');
    }

    notify('We were unable to create laboratory results for this patient', 'error');
  };

  return (
    <LaboratoryResultsForm
      items={[
        { text: 'add new lab', location: '/laboratory-results/add' },
        { text: 'manual input' },
      ]}
      onSaveChanges={handleSaveChanges}
    />
  );
};

export default LaboratoryResultManualInput;
